import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { store, history } from 'redux/store';
import AppContainer from 'containers/App/AppContainer';
import 'config/execute';
import 'creatella-react-components/lib/styles.scss';
import 'styles/styles.scss';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#E6ABAE'
        },
        secondary: {
            main: '#000000'
        }
    },
    typography: {
        fontFamily: 'Spartan'
    }
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <ThemeProvider theme={theme}>
                <AppContainer />
            </ThemeProvider>
        </ConnectedRouter>
    </Provider>
);
