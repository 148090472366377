import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { SENTRY_CONFIG, APP_ENV, APP_ENVS } from 'config/constants';

if (SENTRY_CONFIG.dsn && APP_ENV !== APP_ENVS.LOCAL) {
    Sentry.init({
        ...SENTRY_CONFIG,
        integrations: [new Integrations.BrowserTracing()]
    });
}
