import React, { useCallback, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faComments } from '@fortawesome/free-regular-svg-icons';
import { faBook, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import { useDispatch, useSelector } from 'react-redux';
import dashboardIcon from 'assets/icons/dashboard.svg';
import userIcon from 'assets/icons/user-solid.svg';

import MySLife from 'assets/images/myslife_small_logo.png';
import { ROUTES } from 'config/constants';
import { handleLogout } from 'redux/reducers/auth';

import './AppSideNav.scss';
import { Button } from '@mui/material';

function AppSideNav() {
    const location = useLocation();
    const dispatch = useDispatch();
    const { profile } = useSelector((store) => store.auth);

    const {
        isUsersActive,
        isMessagesActive,
        isContentActive,
        isDashboardActive,
        isProfileActive
    } = useMemo(() => {
        return {
            isUsersActive: [ROUTES.USERS].includes(location.pathname),
            // isUsersActive: [ROUTES.USERS, ROUTES.DASHBOARD].includes(location.pathname),
            isMessagesActive: location.pathname === ROUTES.MESSAGES,
            isContentActive: location.pathname === ROUTES.CONTENT_LIST,
            isDashboardActive: location.pathname === ROUTES.DASHBOARD,
            isProfileActive: location.pathname === ROUTES.PROFILE
        };
    }, [location.pathname]);

    const onLogout = useCallback(() => {
        dispatch(handleLogout());
    }, [dispatch]);

    return (
        <div className='AppSideNav'>
            <img src={MySLife} alt='My S Life' className='AppSideNav_TopLogo' />

            <div className='AppSideNav_MySLife'>
                <div className='AppSideNav_MySLife-logo'>
                    <img src={MySLife} alt='My S Life' />
                </div>
                <div>My S Life</div>
            </div>

            <div className='AppSideNav_Menu'>
                <Link
                    to={ROUTES.DASHBOARD}
                    className={`AppSideNav_Menu-item ${
                        isDashboardActive ? 'AppSideNav_Menu-item--active' : ''
                    }`}>
                    <img src={dashboardIcon} alt='dashboard' />
                    <span>{profile.is_coach ? 'Messages' : 'Dashboard'}</span>
                </Link>
                {profile.is_admin ? (
                    <Link
                        to={ROUTES.USERS}
                        className={`AppSideNav_Menu-item ${
                            isUsersActive ? 'AppSideNav_Menu-item--active' : ''
                        }`}>
                        <FontAwesomeIcon icon={faUser} /> <span>Users</span>
                    </Link>
                ) : null}
                {profile.is_admin ? (
                    <Link
                        to={ROUTES.MESSAGES}
                        className={`AppSideNav_Menu-item ${
                            isMessagesActive
                                ? 'AppSideNav_Menu-item--active'
                                : ''
                        }`}>
                        <FontAwesomeIcon icon={faComments} />{' '}
                        <span>Messages</span>
                    </Link>
                ) : null}
                {profile.is_admin ? (
                    <Link
                        to={ROUTES.CONTENT_LIST}
                        className={`AppSideNav_Menu-item ${
                            isContentActive
                                ? 'AppSideNav_Menu-item--active'
                                : ''
                        }`}>
                        <FontAwesomeIcon icon={faBook} /> <span>Content</span>
                    </Link>
                ) : null}

                {profile.is_coach ? (
                    <Link
                        to={ROUTES.PROFILE}
                        className={`AppSideNav_Menu-item ${
                            isProfileActive
                                ? 'AppSideNav_Menu-item--active'
                                : ''
                        }`}>
                        <img src={userIcon} alt='Profile' />
                        <span>Profile</span>
                    </Link>
                ) : null}

                {/* <Button onClick={onLogout} className='AppSideNav_Menu-logout'>

                </Button> */}

                <Button
                    sx={{
                        fontSize: 16,
                        margin: ' 0 40px',
                        marginTop: '50px',
                        fontFamily: 'Spartan'
                    }}
                    onClick={onLogout}
                    variant='contained'
                    className='AppSideNav_Menu-logout'>
                    <FontAwesomeIcon icon={faSignOutAlt} /> <span>Log out</span>
                </Button>
            </div>
        </div>
    );
}

export default React.memo(AppSideNav);
