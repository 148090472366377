const SET_EXPERT_USERS = 'SET_EXPERT_USERS';
const SET_NORMAL_USERS = 'SET_NORMAL_USERS';
const SET_NORMAL_USERS_ACTIVE_MESSAGES = 'SET_NORMAL_USERS_ACTIVE_MESSAGES';
const SET_EXPERT_USERS_ACTIVE_MESSAGES = 'SET_EXPERT_USERS_ACTIVE_MESSAGESs';
const SET_CLOSED_ROOMS = 'SET_CLOSED_ROOMS';
const SET_OPENED_ROOMS = 'SET_OPENED_ROOMS';
const SET_TICKETS = 'SET_TICKETS';

export const setClosedRooms = (data) => ({
    type: SET_CLOSED_ROOMS,
    data
});

export const setOpenedRooms = (data) => ({
    type: SET_OPENED_ROOMS,
    data
});

export const setTickets = (data) => ({
    type: SET_TICKETS,
    data
});

export const setExpertUsers = (data) => ({
    type: SET_EXPERT_USERS,
    data
});

export const setNormalUsers = (data) => ({
    type: SET_NORMAL_USERS,
    data
});

export const setNormalUsersActiveMessages = (data) => ({
    type: SET_NORMAL_USERS_ACTIVE_MESSAGES,
    data
});

export const setExpertUsersActiveMessages = (data) => ({
    type: SET_EXPERT_USERS_ACTIVE_MESSAGES,
    data
});

const INITIAL_STATE = {
    expertUsers: [],
    normalUsers: [],
    normalUsersActiveMessages: [],
    expertUsersActiveMessages: [],
    closedRooms: [],
    openedRooms: [],
    tickets: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_EXPERT_USERS:
            return {
                ...state,
                expertUsers: [...action.data]
            };
        case SET_NORMAL_USERS:
            return {
                ...state,
                normalUsers: [...action.data]
            };
        case SET_NORMAL_USERS_ACTIVE_MESSAGES:
            return {
                ...state,
                normalUsersActiveMessages: [...action.data]
            };
        case SET_EXPERT_USERS_ACTIVE_MESSAGES:
            return {
                ...state,
                expertUsersActiveMessages: [...action.data]
            };
        case SET_CLOSED_ROOMS:
            return {
                ...state,
                closedRooms: [...action.data]
            };
        case SET_OPENED_ROOMS:
            return {
                ...state,
                openedRooms: [...action.data]
            };
        case SET_TICKETS:
            return {
                ...state,
                tickets: [...action.data]
            };
        default:
            return { ...state };
    }
}
