import * as Sentry from '@sentry/browser';
import axios, { endPoints, setAuthToken, unsetAuthToken } from 'utils/api';
import { deleteAuthToken, getAuthToken } from './utils/auth/helpers';

const IS_APP_READY = 'IS_APP_READY';

const AUTH_SUCCESS = 'AUTH_SUCCESS';
const AUTH_LOGOUT_START = 'AUTH_LOGOUT_START';
const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';

const UPDATE_PROFILE = 'UPDATE_PROFILE';

export const appIsReady = () => ({
    type: IS_APP_READY
});

export const authSuccess = (profile) => ({
    type: AUTH_SUCCESS,
    profile
});

export const updateProfile = (profile) => ({
    type: UPDATE_PROFILE,
    profile
});

const authLogoutStart = () => ({
    type: AUTH_LOGOUT_START
});

const authLogoutSuccess = () => ({
    type: AUTH_LOGOUT_SUCCESS
});

export function handleAutoLogin() {
    return async (dispatch) => {
        try {
            const token = getAuthToken();

            if (token !== null) {
                await setAuthToken(token);

                const userDataResponse = await axios.get(endPoints.authedUser);

                dispatch(authSuccess(userDataResponse.data));
            }
        } catch (err) {
            dispatch(handleLogout(true));
        } finally {
            dispatch(appIsReady());
        }
    };
}

export function handleLogout(isAutoLogout = false) {
    return async (dispatch) => {
        dispatch(authLogoutStart());

        try {
            if (getAuthToken()) {
                await axios.post(endPoints.logout);
            }
        } catch (err) {
            // logout fails when called from unauthed client
            // therefor when called from invalid login
            // silently catch this error
            // in anyway the failing of this api at this point should not cause any issues
        } finally {
            deleteAuthToken();
            unsetAuthToken();

            if (isAutoLogout === true) {
                dispatch(authLogoutSuccess());
            } else {
                window.location = window.location.origin;
            }
        }
    };
}

const INITIAL_STATE = {
    isAppReady: false,
    isUpdatingProfile: false,
    isAuthed: false,
    isLoggingOut: false,

    profile: {}
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case IS_APP_READY:
            return {
                ...state,
                isAppReady: true
            };

        case AUTH_SUCCESS: {
            const { profile } = action;
            const { id, email } = profile;

            Sentry.configureScope((scope) => {
                scope.setUser({ id, email });
            });

            return {
                ...state,
                isAuthenticating: false,
                isAuthed: true,
                profile
            };
        }

        case UPDATE_PROFILE: {
            const { profile } = action;

            return {
                ...state,
                isUpdatingProfile: false,
                profile: {
                    ...state.profile,
                    ...profile
                }
            };
        }

        case AUTH_LOGOUT_START:
            return {
                ...state,
                isLoggingOut: true
            };

        case AUTH_LOGOUT_SUCCESS:
            return {
                ...INITIAL_STATE,
                isAppReady: true
            };

        default:
            return state;
    }
};
