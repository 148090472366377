/****
 *
 * window & process.env constants
 *
 */
export const ELOQUENT = window.Eloquent;
export const AXIOS = window._axios;

// see https://github.com/SchwSimon/creatella-react-components#withasynccaller
window._withAsyncCallerAxios = window._BaseAxios;

export const APP_ENV = process.env.REACT_APP_ENV;
export const APP_URL = process.env.REACT_APP_URL;
export const API_URL = process.env.REACT_APP_API_URL;

/****
 *
 * Storage keys
 *
 */
export const STORAGEKEY_AUTH_TOKEN = 'sk-authToken';
export const STORAGEKEY_APP_LANGUAGE = 'sk-appLanguage';

/****
 *
 * Configs
 *
 */
// App default language (also used for the <html /> lang='' attribute)
// see https://developer.paciellogroup.com/blog/2016/06/using-the-html-lang-attribute/
// NOTE: make sure the language key defined here matches the default helmet key
// // see `executors/i18n/helmet`
export const APP_LANGUAGE_DEFAULT = 'en'; // TODO_REPLACE(en | de | fr | etc..)

// App environments
// see .env.production & .env.development
// values must match the values defined in these files
export const APP_ENVS = {
    LOCAL: 'local',
    STAGING: 'staging',
    PRODUCTION: 'production'
};

// SENTRY - see https://docs.sentry.io/error-reporting/configuration/?platform=javascript
export const SENTRY_CONFIG = {
    environment: APP_ENV,
    dsn: 'https://23c2b9bd963645a5baa239e40de3d490@o1045007.ingest.sentry.io/6020268'
};

// WEBFONT config - see https://github.com/typekit/webfontloader
export const WEBFONT_CONFIG = {
    google: {
        families: [
            'Montserrat:300',
            'Montserrat:400',
            'Montserrat:500',
            'Montserrat:600',
            'Montserrat:700',
            'Montserrat:900'
        ]
    }
};

// Social networks config
export const SOCIAL_NETWORK_URLS = {
    FACEBOOK: 'TODO_REPLACE',
    INSTAGRAM: 'TODO_REPLACE',
    TWITTER: 'TODO_REPLACE',
    LINKEDIN: 'TODO_REPLACE'
    // ...
};

export const FACEBOOK_APP_ID = 'TODO_REPLACE';

/****
 *
 * App routing
 *
 */
export const ROUTES = {
    HOME: '/',
    USERS: '/users',
    MESSAGES: '/messages',
    LOGIN: '/login',
    CREATE_VIDEO: '/create-video',
    CREATE_ARTICLE: '/create-article',
    CREATE_PODCAST: '/create-podcast',
    DASHBOARD: '/dashboard',
    CONTENT_LIST: '/content',
    PROFILE: '/profile'
};

export const ROUTE_REDIRECT_AFTER_LOGIN = ROUTES.DASHBOARD;
export const ROUTE_REDIRECT_FROM_UNAUTHED_ROUTE = ROUTES.HOME;
export const ROUTE_REDIRECT_FROM_AUTHED_ROUTE = ROUTES.LOGIN;

/****
 *
 * Pusher
 *
 */

export const pusherKey = process.env.REACT_APP_PUSHER_KEY;

export const EMITTER_EVENTS = {
    SORT_MESSAGES: 'SORT_MESSAGES',
    ONLINE_STATUS_CHANGED: 'ONLINE_STATUS_CHANGED',
    TICKET_TAKEN: 'TICKET_TAKEN',
    TICKET_CREATED: 'TICKET_CREATED',
    ROOM_CLOSED: 'ROOM_CLOSED',
    LATEST_MESSAGE_UPDATE: 'ROOM_UPDATE',
    NEW_MESSAGE_CREATED: 'NEW_MESSAGE_CREATED' // used only in the dashboard screen when for non members
};

// ...
// export const GENDER = {
//     MAN: 1,
//     WOMAN: 2,
//     NOT_DEFINED: 3
// };
export const GENDER = {
    1: 'I am not a woman',
    2: 'I am a woman',
    3: 'I am not defined by a genre'
};

export const JOIN_REASON = {
    1: 'Here to learn',
    2: 'Here to make friends'
};

/**
 * Message values
 */
export const MESSAGE_TO_COMMUNITY = {
    0: 'Vous n\'êtes pas seule',
    1: 'C\'est mon corps, mes choix !',
    2: 'Vous êtes forte !'
};
