import axios from 'axios';
import { API_URL } from 'config/constants';

let apiTokenAuthorizationInterceptor;

const axiosInstance = axios.create({
    baseURL: API_URL
});

export const endPoints = {
    login: 'auth/login',
    logout: 'auth/logout',
    register: 'auth/register',
    forgotPassword: 'auth/forgot-password',
    resetPassword: 'auth/reset-password',
    firebaseToken: 'firebase-tokens',
    authedUser: 'me',
    requestOtp: 'request-verification-otp',
    verify: 'verify',
    categories: 'categories',
    presignedUrl: 'upload/signed-url',
    groups: 'groups',
    posts: 'posts',
    messaging: 'messaging',
    notifications: 'notifications',
    users: 'users',
    contents: 'contents',
    categoryGroups: 'category-groups',
    plans: 'subscriptions/plans',
    coaches: 'coaches',
    tickets: 'tickets'

};

export function setAuthToken(token) {
    apiTokenAuthorizationInterceptor = axiosInstance.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${token || ''}`;

        return config;
    });
}

export function unsetAuthToken() {
    axiosInstance.interceptors.request.eject(apiTokenAuthorizationInterceptor);
}

export default axiosInstance;
