import { lazy } from 'react';

export const LoginContainer = lazy(() => import('containers/Login'));
export const UsersContainer = lazy(() => import('containers/Users'));
export const MessagesContainer = lazy(() => import('containers/Messages'));
export const PageNotFoundContainer = lazy(() =>
    import('containers/PageNotFound')
);
export const CreateContentContainer = lazy(() =>
    import('containers/CreateContent')
);
export const ContentListContainer = lazy(() =>
    import('containers/ContentList')
);
export const DashboardContainer = lazy(() => import('containers/Dashboard'));
export const ProfileContainer = lazy(() => import('containers/Profile'));
