/***
 *
 * NOTE: This is ONLY for <Helmet /> in `src/containers/App/AppContainer`
 *
 */
import { getAppLanguage } from 'redux/reducers/utils/i18n/helpers';
import { FACEBOOK_APP_ID } from 'config/constants';

const STATIC_CONFIG = {
    image: 'TODO_REPLACE(optimal: 1200px x 630px (max 5mb, ratio: 1.91:1))',
    siteName: 'TODO_REPLACE(official name of the site)',
    type: 'website',
    language: getAppLanguage(),
    twitterSite: 'TODO_REPLACE(@twitter_username see: https://developer.twitter.com/en/docs/tweets/optimize-with-cards/guides/getting-started)',
    twitterCard: 'summary',
    fbAppId: FACEBOOK_APP_ID,
    children: null
};

// HELMET - see https://github.com/SchwSimon/creatella-react-components#helmet-
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    en: {
        ...STATIC_CONFIG,
        description: 'MySLife - Admin',
        title: 'MySLife - Admin'
    }
};
