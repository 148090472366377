/* eslint-disable react/jsx-max-props-per-line */

import PropTypes from 'prop-types';
import React, { Component, Suspense } from 'react';
import AppLoading from '../Loading/AppLoading';
import { Switch, Route } from 'react-router-dom';
import { ROUTES } from 'config/constants';
import { UnauthedRoute, AuthedRoute } from './components/RouteWrappers';
import {
    ContentListContainer,
    CreateContentContainer,
    DashboardContainer,
    LoginContainer,
    MessagesContainer,
    PageNotFoundContainer,
    UsersContainer,
    ProfileContainer
} from './config/routes';

export default class AppRouter extends Component {
    static propTypes = {
        isAuthed: PropTypes.bool.isRequired
    };

    shouldComponentUpdate(nextProps) {
        const { isAuthed } = this.props;

        if (isAuthed !== nextProps.isAuthed) {
            return true;
        }

        return false;
    }

    render() {
        const { isAuthed } = this.props;

        return (
            <Suspense fallback={<AppLoading />}>
                <Switch>
                    <UnauthedRoute path={ROUTES.LOGIN} exact={true} component={LoginContainer} isAuthed={isAuthed} />

                    <AuthedRoute path={ROUTES.HOME} exact={true} component={DashboardContainer} isAuthed={isAuthed} />
                    <AuthedRoute path={ROUTES.USERS} exact={true} component={UsersContainer} isAuthed={isAuthed} />
                    <AuthedRoute path={ROUTES.MESSAGES} exact={true} component={MessagesContainer} isAuthed={isAuthed} />
                    <AuthedRoute path={ROUTES.CREATE_ARTICLE} exact={true} component={CreateContentContainer} isAuthed={isAuthed} />
                    <AuthedRoute path={ROUTES.CREATE_PODCAST} exact={true} component={CreateContentContainer} isAuthed={isAuthed} />
                    <AuthedRoute path={ROUTES.CREATE_VIDEO} exact={true} component={CreateContentContainer} isAuthed={isAuthed} />

                    <AuthedRoute path={ROUTES.CONTENT_LIST} exact={true} component={ContentListContainer} isAuthed={isAuthed} />
                    <AuthedRoute path={ROUTES.DASHBOARD} exact={true} component={DashboardContainer} isAuthed={isAuthed} />
                    <AuthedRoute path={ROUTES.PROFILE} exact={true} component={ProfileContainer} isAuthed={isAuthed} />

                    <Route component={PageNotFoundContainer} />
                </Switch>
            </Suspense>
        );
    }
}
