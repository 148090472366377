import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import AppRouter from './components/Router/AppRouter';
import AppLoading from './components/Loading/AppLoading';
import ErrorBoundary from 'creatella-react-components/lib/ErrorBoundary';
import Helmet from 'creatella-react-components/lib/Helmet';
import { handleAutoLogin } from 'redux/reducers/auth';
import HELMET_DEFAULT_CONFIG from 'config/executors/i18n-resources/helmet';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './AppContainer.scss';
import AppSideNav from './components/SideNav/AppSideNav';

import withPusher from 'HOCs/usePusher';
import { ROUTES } from 'config/constants';
import classNames from 'classnames';

class AppContainer extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        autoLogin: PropTypes.func.isRequired,
        isAppReady: PropTypes.bool.isRequired,
        isAuthed: PropTypes.bool.isRequired,
        language: PropTypes.string.isRequired
    };

    componentDidMount() {
        const { autoLogin } = this.props;

        autoLogin();
    }

    shouldComponentUpdate(nextProps) {
        const { location, isAppReady, isAuthed, language } = this.props;
        const { pathname } = location;

        if (pathname !== nextProps.location.pathname ||
            isAppReady !== nextProps.isAppReady ||
            isAuthed !== nextProps.isAuthed ||
            language !== nextProps.language) {
            return true;
        }

        return false;
    }

    onError = (error, info) => {
        Sentry.withScope((scope) => {
            scope.setExtras('Info', info);
            Sentry.captureException(error);
        });
    };

    renderAuthedContent = () => {
        const { pathname } = this.props.location;

        const isContentPageActive = pathname === ROUTES.CREATE_ARTICLE || pathname === ROUTES.CREATE_PODCAST || pathname === ROUTES.CREATE_VIDEO;

        return (
            <div className={classNames('AppContainer_Authed', { 'AppContainer_Authed--compact': isContentPageActive })}>
                { isContentPageActive ? null : <AppSideNav />}
                <AppRouter isAuthed={this.props.isAuthed} />
            </div>
        );
    };

    render() {
        const { isAppReady, location, isAuthed, language } = this.props;
        const { pathname } = location;

        if (isAppReady) {
            return (
                <main className='AppContainer'>
                    <ErrorBoundary
                        pathname={pathname}
                        onError={this.onError}>
                        {isAuthed
                            ? this.renderAuthedContent()
                            : <AppRouter isAuthed={isAuthed} />
                        }
                    </ErrorBoundary>

                    <Helmet
                        pathname={pathname}
                        defaultConfig={HELMET_DEFAULT_CONFIG[language]} />

                    <ToastContainer
                        hideProgressBar={true}
                        closeOnClick={true}
                        pauseOnHover={false}
                        position='top-right' />
                </main>
            );
        }

        return (
            <main className='AppContainer'>
                <Helmet
                    pathname={pathname}
                    defaultConfig={HELMET_DEFAULT_CONFIG[language]} />

                <AppLoading />
            </main>
        );
    }
}

const mapStateToProps = ({ auth, i18n }) => {
    const { isAppReady, isAuthed } = auth;
    const { language } = i18n;

    return {
        isAppReady,
        isAuthed,
        language
    };
};

const mapDispatchToProps = (dispatch) => ({
    autoLogin: (location) => {
        dispatch(handleAutoLogin(location));
    }
});

export default withPusher(withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AppContainer)
));
