import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import {
    API_URL,
    EMITTER_EVENTS,
    pusherKey
} from 'config/constants';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { connect } from 'react-redux';
import { getAuthToken } from 'redux/reducers/utils/auth/helpers';
import { compose } from 'redux';
import { sendMessage } from 'redux/reducers/chat';
import { setExpertUsers, setNormalUsers } from 'redux/reducers/users';
import Emitter from 'utils/emitter';

window.Pusher = Pusher;

const notifTypes = {
    NewMessageReceived: 'App\\Notifications\\NewMessageReceived',
    NewMessageSent: 'App\\Notifications\\NewMessageSent'
};

function withPusher(Component) {
    class HOC extends React.Component {
        static propTypes = {
            isAuthed: PropTypes.bool,
            userId: PropTypes.number,
            updateMessages: PropTypes.func,
            counterUserId: PropTypes.number,
            normalUsers: PropTypes.array,
            expertUsers: PropTypes.array,
            updateExpertUsers: PropTypes.func,
            updateNormalUsers: PropTypes.func,
            profile: PropTypes.object
        };

        constructor(props) {
            super(props);
            this.EchoClient = createRef(null);
        }

        componentDidUpdate() {
            const { isAuthed } = this.props;

            if (isAuthed) {
                this.setupRealtimeEvents();
            }
        }

        componentWillUnmount() {
            const { userId } = this.props;

            if (this.EchoClient.current && userId) {
                this.EchoClient.current.leaveChannel(
                    `App.Models.User.${userId}`
                );
            }
        }

        setupRealtimeEvents = async () => {
            const { userId, updateMessages, counterUserId, profile } =
                this.props;

            const handleReceiveNotification = async (notif) => {
                const data = notif.data;

                // console.log(
                //     'handleReceiveNotification received',
                //     notif,
                //     counterUserId
                // );

                if (notif.type === notifTypes.NewMessageReceived) {
                    const isAdminConversation = data.recipient.is_admin || data.sender.is_admin;

                    if (!isAdminConversation) {
                        // only for the dashboard screen:
                        Emitter.emit(
                            EMITTER_EVENTS.LATEST_MESSAGE_UPDATE,
                            data
                        );
                    } else {
                        // only for the messages screen
                        if (data.recipient.id === userId) {
                            Emitter.emit(EMITTER_EVENTS.SORT_MESSAGES, data);
                        }
                    }

                    // for both dashboard and messages screen
                    if (
                        data.sender.id !== userId &&
                            counterUserId === data.sender.id
                    ) {
                        const _message = {
                            ...data.message,
                            msg: data.message.content,
                            isCounterUser: false
                        };

                        updateMessages(_message);
                    }
                }

                if (notif.type === notifTypes.NewMessageSent) {
                    // update last message for myself sent messages as well
                    Emitter.emit(
                        EMITTER_EVENTS.LATEST_MESSAGE_UPDATE,
                        data
                    );
                }
            };

            const authToken = await getAuthToken();

            this.EchoClient.current &&
                this.EchoClient.current.connector.pusher.disconnect();

            this.EchoClient.current = new Echo({
                broadcaster: 'pusher',
                cluster: 'eu',
                authEndpoint: `${API_URL}broadcasting/auth`,
                key: pusherKey,
                activityTimeout: 60000,
                auth: {
                    headers: {
                        Authorization: 'Bearer ' + authToken
                    }
                },
                encrypted: true
            });
            this.EchoClient.current
                .private(`App.Models.User.${userId}`)
                .notification(handleReceiveNotification);
            this.EchoClient.current
                .private('App.Models.User.All')
                .listen('UserOnlineStatusUpdated', ({ data }) => {
                    Emitter.emit(
                        EMITTER_EVENTS.ONLINE_STATUS_CHANGED,
                        data.user
                    );

                    // dispatch(userStatusChanged(event.data.user));
                });

            if (profile.is_admin) {
                this.EchoClient.current
                    .private('App.Models.Admin.All')
                    .listen('TicketTaken', ({ data }) => {
                        Emitter.emit(EMITTER_EVENTS.TICKET_TAKEN, data);
                    }).listen('TicketCreated', ({ data }) => {
                        Emitter.emit(EMITTER_EVENTS.TICKET_CREATED, data);
                    }).listen('NewMessageCreated', ({ data }) => {
                        Emitter.emit(EMITTER_EVENTS.NEW_MESSAGE_CREATED, data);
                    })
                    .listen('ChatRoomClosed', ({ data }) => {
                        Emitter.emit(EMITTER_EVENTS.ROOM_CLOSED, data);
                    }).notification(handleReceiveNotification);
            }

            if (profile.is_coach) {
                this.EchoClient.current
                    .private('App.Models.Coach.All')
                    .listen('TicketTaken', ({ data }) => {
                        Emitter.emit(EMITTER_EVENTS.TICKET_TAKEN, data);
                    }).listen('TicketCreated', ({ data }) => {
                        Emitter.emit(EMITTER_EVENTS.TICKET_CREATED, data);
                    }).listen('NewMessageCreated', ({ data }) => {
                        Emitter.emit(EMITTER_EVENTS.NEW_MESSAGE_CREATED, data);
                    })
                    .listen('ChatRoomClosed', ({ data }) => {
                        Emitter.emit(EMITTER_EVENTS.ROOM_CLOSED, data);
                    }).notification(handleReceiveNotification);
            }
        };

        render() {
            return <Component {...this.props} />;
        }
    }

    return HOC;
}

const mapStateToProps = ({ auth, chat, users }) => {
    const { profile, isAuthed } = auth;
    const { counterUser } = chat;
    const { normalUsers, expertUsers } = users;

    return {
        userId: profile && profile.id,
        isAuthed,
        counterUserId: counterUser && counterUser.id,
        normalUsers,
        expertUsers,
        profile
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateMessages: (message) => {
        dispatch(sendMessage(message));
    },
    updateNormalUsers: (users) => {
        dispatch(setNormalUsers(users));
    },
    updateExpertUsers: (users) => {
        dispatch(setExpertUsers(users));
    }
});

const composedWithPusher = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPusher
);

export default composedWithPusher;
