import React, { PureComponent } from 'react';
import './AppLoading.scss';
import { CircularProgress } from '@mui/material';

export default class AppLoading extends PureComponent {
    render() {
        return (
            <div className='AppLoading'>
                <CircularProgress color='inherit' />

            </div>
        );
    }
}
