import {
    setExpertUsers,
    setExpertUsersActiveMessages,
    setNormalUsers,
    setNormalUsersActiveMessages
} from '../users';

const SET_ACTIVE_ROOM = 'SET_ACTIVE_ROOM';
const SET_ACTIVE_ROOM_MESSAGES = 'SET_ACTIVE_ROOM_MESSAGES';
const CLEAR_ACTIVE_ROOM_MESSAGES = 'CLEAR_ACTIVE_ROOM_MESSAGES';
const SEND_MESSAGE = 'SEND_MESSAGE';
const SET_COUNTER_USER = 'SET_COUNTER_USER';
const COUNTER_USER_UPDATE_ONLINE_STATUS = 'COUNTER_USER_UPDATE_ONLINE_STATUS';
const SET_CURRENT_USER_ONLINE_STATUS = 'SET_CURRENT_USER_ONLINE_STATUS';
const SET_CURRENT_USER = 'SET_CURRENT_USER';
const SET_READONLY_ROOM = 'SET_READONLY_ROOM';
const SET_THIRDPARTY_MODE = 'SET_THIRDPARTY_MODE';
const SET_COACH_ID = 'SET_COACH_ID';
const RESET_ALL_CHAT_DATA = 'RESET_ALL_CHAT_DATA';

export const setActiveRoom = (id) => ({
    type: SET_ACTIVE_ROOM,
    id
});

export const setReadonlyRoom = () => ({
    type: SET_READONLY_ROOM
});

export const setThirdPartyMode = (isThirdParty) => ({
    type: SET_THIRDPARTY_MODE,
    isThirdParty
});

export const setCoachId = (coachId) => ({
    type: SET_COACH_ID,
    coachId
});

export const resetAllChatData = () => ({
    type: RESET_ALL_CHAT_DATA
});

export const setActiveRoomMessages = (data) => ({
    type: SET_ACTIVE_ROOM_MESSAGES,
    data
});

export const sendMessage = (data) => ({
    type: SEND_MESSAGE,
    data
});

export const setCounterUser = (data) => ({
    type: SET_COUNTER_USER,
    data
});

export const setCurrentUser = (data) => ({
    type: SET_CURRENT_USER,
    data
});

export const clearRoom = () => ({
    type: CLEAR_ACTIVE_ROOM_MESSAGES
});

export const setCounterUserOnlineStatus = (isOnline) => ({
    type: COUNTER_USER_UPDATE_ONLINE_STATUS,
    isOnline
});

export const setCurrentUserOnlineStatus = (isOnline) => ({
    type: SET_CURRENT_USER_ONLINE_STATUS,
    isOnline
});

const INITIAL_STATE = {
    activeRoomId: null,
    activeRoomMessages: [],
    counterUser: null,
    currentUser: null,
    readOnly: false,
    isThirdPartyViewModeActive: false,
    coachId: null
};

export const handleSetCounterUser =
    (
        counterUser,
        chatRoomId
    ) =>
        async (dispatch, getState) => {
            const isNormalUser = !counterUser.is_expert;

            dispatch(setCounterUser(counterUser));
            const { users } = getState();

            if (isNormalUser) {
                const normalUsers = users.normalUsers;
                const normalUsersActiveMessages = users.normalUsersActiveMessages;

                // update in the users list
                const updatedRoomIndex = normalUsers.findIndex(
                    (u) => u.id === chatRoomId
                );

                if (updatedRoomIndex !== -1) {
                    const updatedRoom = normalUsers[updatedRoomIndex];

                    normalUsers.splice(updatedRoomIndex, 1, {
                        ...updatedRoom,
                        unread_messages_count: 0
                    });
                    dispatch(setNormalUsers([...normalUsers]));
                }

                // update in the active messages list
                const updatedMessageIndex = normalUsersActiveMessages.findIndex(
                    (m) => m.latest_message.sender.id === counterUser.id
                );

                if (updatedMessageIndex !== -1) {
                    const updatedMessage =
                    normalUsersActiveMessages[updatedMessageIndex];

                    normalUsersActiveMessages.splice(updatedMessageIndex, 1, {
                        ...updatedMessage,
                        unread_messages_count: 0
                    });
                    dispatch(
                        setNormalUsersActiveMessages([...normalUsersActiveMessages])
                    );
                }
            } else {
                const expertUsersActiveMessages = users.expertUsersActiveMessages;
                const expertUsers = users.expertUsers;
                const updatedRoomIndex = expertUsers.findIndex(
                    (u) => u.id === counterUser.id
                );

                if (updatedRoomIndex !== -1) {
                    const updatedUser = expertUsers[updatedRoomIndex];

                    expertUsers.splice(updatedRoomIndex, 1, {
                        ...updatedUser,
                        unread_messages_count: 0
                    });

                    dispatch(setExpertUsers([...expertUsers]));
                }

                const updatedMessageIndex = expertUsersActiveMessages.findIndex(
                    (m) => m.latest_message.sender.id === counterUser.id
                );

                if (updatedMessageIndex !== -1) {
                    const updatedMessage =
                    expertUsersActiveMessages[updatedMessageIndex];

                    expertUsersActiveMessages.splice(updatedMessageIndex, 1, {
                        ...updatedMessage,
                        unread_messages_count: 0
                    });
                    dispatch(
                        setExpertUsersActiveMessages([...expertUsersActiveMessages])
                    );
                }
            }
        };

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case RESET_ALL_CHAT_DATA:
            return {
                activeRoomId: null,
                activeRoomMessages: [],
                counterUser: null,
                currentUser: null,
                readOnly: false,
                isThirdPartyViewModeActive: false,
                coachId: null
            };
        case SET_ACTIVE_ROOM:
            return {
                ...state,
                activeRoomId: action.id,
                readOnly: false,
                isThirdPartyViewModeActive: false,
                coachId: null
            };
        case SET_COUNTER_USER:
            return {
                ...state,
                counterUser: action.data
            };
        case SET_CURRENT_USER:
            return {
                ...state,
                currentUser: action.data
            };
        case SET_ACTIVE_ROOM_MESSAGES:
            return {
                ...state,
                activeRoomMessages: [...action.data]
            };
        case SEND_MESSAGE:
            return {
                ...state,
                activeRoomMessages: [action.data, ...state.activeRoomMessages]
            };
        case SET_READONLY_ROOM:
            return {
                ...state,
                readOnly: true
            };
        case SET_THIRDPARTY_MODE:
            return {
                ...state,
                isThirdPartyViewModeActive: action.isThirdParty
            };
        case SET_COACH_ID:
            return {
                ...state,
                coachId: action.coachId
            };
        case CLEAR_ACTIVE_ROOM_MESSAGES:
            return {
                ...state,
                activeRoomMessages: []
            };
        case COUNTER_USER_UPDATE_ONLINE_STATUS:
            return {
                ...state,
                counterUser: {
                    ...state.counterUser,
                    is_online: action.isOnline
                }
            };
        case SET_CURRENT_USER_ONLINE_STATUS:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    is_online: action.isOnline
                }
            };
        default:
            return state;
    }
}
